import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {
    condition:{
    },
  },
  getters: {
    getCondition(state){
      return state.condition
    }
  },
  mutations: {
    resetCondition (state) {
      for (let conditionKey in state.condition) {
        state.condition[conditionKey] = undefined
      }
    },
  },
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/salesreturnbound/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/salesreturnbound/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/salesreturnbound/edit', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/salesreturnbound/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saves(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/salesreturnbound/saves', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/salesreturnbound/state', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    commit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/salesreturnbound/commit', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delete(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/salesreturnbound/delete', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    warehouselist(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/salesreturnbound/warehouselist', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateWhInfo(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/salesreturnbound/updateWhInfo', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createAllOutBound(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/salesreturnbound/createAllOutBound', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    isPreReturnBoundCreate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/salesreturnbound/isPreReturnBoundCreate', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
