<template>

  <div>
    <b-card
            no-body
            class="mb-0 list-table"
    >
      <div class="m-2">
        <b-row>




          <b-col
                  cols="12"
                  md="2"
          >
            <div style="width: 130px"></div>
            <v-select
                    :options="warehouseIdLocationList"
                    :clearable="false"
                    v-model="locationInfo"
                    @input="changeSelectLocation($event)"
                    class="select-size-sm"
                    placeholder="请选择仓位"
                    label="label"

            />
          </b-col>

          <b-col
                  cols="12"
                  md="2"
          >
            <div style="width: 130px"></div>
            <el-date-picker
                    v-model="yearAndMonthHead"
                    type="month"
                    placeholder="选择日期"
                    :clearable="false"
                    format="yyyy 年 MM 月 "
                    value-format="timestamp"
                    :picker-options="pickerOption"
            >
            </el-date-picker>
          </b-col>

          <b-col
                  cols="12"
                  md="2"
          >
            <b-button
                    variant="success"
                    class="mr-1"
                    @click="setValue()"
            >
              批量赋值
            </b-button>
          </b-col>

          <b-col
                  cols="12"
                  md="4"
          >
          </b-col>

          <b-col
                  cols="12"
                  md="2"
          >
            <b-button
                    variant="success"
                    class="mr-1"
                    @click="allSave"
                    v-if="showButton"
            >
              入库完成
            </b-button>
          </b-col>

        </b-row>

      </div>
      <ValidationObserver ref="observer">
        <b-table
                ref="refListTable"
                class="position-relative"
                :items="searchList"
                responsive
                hover
                small
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="orderBy"
                show-empty
                empty-text="未找到记录"
                :sort-desc.sync="isSortDirDesc"
                :tbody-tr-class="rowClass"
                v-model="dataList"
        >

          <template v-slot:head(select)="data"  v-if="role_id===14">
            <b-form-checkbox v-model="allchecked" @change="toggleAll" :indeterminate="indeterminate">
            </b-form-checkbox>
          </template>


          <template v-slot:cell(select)="row">
            <b-form-checkbox @change="checkboxClick(row.item)" v-model="isCheckedObj['index'+row.item.id]">
            </b-form-checkbox>
          </template>


          <template #cell(name)="data">
            [#{{ data.item.id }}]{{ data.item.name }}
            <feather-icon
                    icon="TwitchIcon"
                    size="21"
                    variant="success"
                    :id="`item-row-${data.item.id}`"
            />
            <b-tooltip
                    :target="`item-row-${data.item.id}`"
                    placement="top"
            >
              <p class="mb-0">
                69码：{{ data.item.ext.product.code || '' }}
              </p>
              <p class="mb-0">
                箱规：{{ data.item.ext.product.box_quantity || '' }}
              </p>
              <p class="mb-0">
                产品批次：{{ data.item.batch_no || '' }}
              </p>
              <p class="mb-0">
                生产日期：{{ toDate(data.item.production_date) || '' }}
              </p>
              <p class="mb-0">
                有效日期：{{ toDate(data.item.expiry_date) || '' }}
              </p>
            </b-tooltip>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div >
              <feather-icon icon="AlignJustifyIcon"  @click="showModal(data.item)" />
            </div>
          </template>

        </b-table>
      </ValidationObserver>
    </b-card>
    <b-modal
            id="receiveBackModal"
            ok-only
            ok-title="确认"
            centered
            size="xl"
            :title="'退货入库单仓位数量选择'"
            hide-footer
            no-close-on-backdrop
            no-close-on-esc
    >
      <AllotoutboundItemForm :type="type" :warehouseId="parseInt(warehouseId)" :goodLocation="goodLocation" :condition="condition" v-on:table="fromChildren">

      </AllotoutboundItemForm>

    </b-modal>
  </div>
</template>

<script>
  import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination,
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import store from '@/store'
  import {ref, onUnmounted, reactive, toRefs, computed} from '@vue/composition-api'
  import {
    avatarText,
    toTime,
    toDate,
    getCodeOptions,
    getCode,
    getCodeLabel,
    getCodeColor,
    removeDuplicate,
    removeOneArrFromArr,
    getBatchNoForTime,
    isEmpty,
    addNumber
  } from '@core/utils/filter'
  import salesreturnbounditemUseList from './warehouseUseList'
  import salesreturnbounditemStore from './salesreturnbounditemStore'
  import {useRouter} from '@core/utils/utils'
  import {useToast} from 'vue-toastification/composition'
  import WarehouseLocationList from '@/views/apps/warehouselocation/modal/WarehouseLocationList'
  import warehouselocationStore from '@/views/apps/warehouselocation/warehouselocationStore'
  import {getUserData} from "@/auth/utils";
  import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
  import salesreturnboundStore from "@/views/apps/salesreturnbound/salesreturnboundStore";
  import AllotoutboundItemForm from "@/views/apps/salesreturnbounditem/AllotoutboundItemForm";
  import ElDatePicker from "element-ui/packages/date-picker";
  import ElAlert from "element-ui/packages/alert";
  import Notification from "element-ui/packages/notification";
  import Ripple from "vue-ripple-directive";
  export default {
    components: {
      WarehouseLocationList,
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      vSelect,
      AttachmentUpload,
      AllotoutboundItemForm,
      ElDatePicker,
      ElAlert,
      Notification
    },

    methods: {
      //勾选的明细 统一赋值仓位
      changeSelectLocation(event){
        this.locationInfo = event ;
      },
      toggleAll(checked) {
        const array = []
        for (let i = 0; i < this.checked.length; i++) {
          for (let j = 0; j < this.dataList.length; j++) {
            if (this.checked.length > 0 && this.checked[i].entity_id == this.dataList[j].entity_id) {
              array.push(i)
            }
          }
        }
        for (let i = array.length - 1; i >= 0; i--) {
          this.checked.splice(array[i], 1);
        }
        this.dataList.forEach(element => {
          if (checked) {
            this.checked.push(element);
          }
          this.isCheckedObj['index' + element.id] = checked;
        });
        this.$forceUpdate()

      },
      checkboxClick(id) {
        let index = -1;
        for (let i = 0; i < this.checked.length; i++) {
          if (this.checked[i].entity_id == id.entity_id) {
            index = i
          }
        }
        if (index > -1) {
          this.checked.splice(index, 1);
        } else {
          this.checked.push(id);
        }
        this.$forceUpdate()
      },

    },
    data() {
      return {
        selectedIndex: {},
        modalFlag: false,
        showButton: true,
        role_id: 0,
        memo: '',
        condition: {},
        warehouseId:'',
        returnQtyItem:[],
        type:'sales_return',
        yearAndMonthHead:'', //标头选择年月
        locationInfo:{},
        pickerOption: {
          disabledDate(time) {
            return time.getTime() > Date.now() - 8.64e6;
          }
        },
        checked: [],  //勾选明细
        allchecked: false,
        indeterminate: false,
        isCheckedObj: {},
        loadingVisible:true,
      }
    },
    watch: {
      checked: {
        handler(newVal, oldVal) {
          let show = 0;
          for (let i = 0; i < this.checked.length; i++) {
            for (let j = 0; j < this.dataList.length; j++) {
              if (this.checked[i].entity_id == this.dataList[j].entity_id) {
                show += 1;
              }
            }
          }
          if (newVal.length == 0) {
            this.indeterminate = false
            this.allchecked = false
          } else if (this.dataList.length === show) {
            this.indeterminate = false
            this.allchecked = true
          } else if (show === 0) {
            this.indeterminate = false
            this.allchecked = false
          } else {
            this.indeterminate = true
            this.allchecked = false
          }
          this.$forceUpdate()
        },
        deep: true//深度
      },
      dataList: {
        handler(newVal, oldVal) {
          let show = 0;
          for (let i = 0; i < this.checked.length; i++) {
            for (let j = 0; j < this.dataList.length; j++) {
              if (this.checked[i].entity_id == this.dataList[j].entity_id) {
                show += 1;
              }
            }
          }
          if (this.dataList.length === show) {
            this.indeterminate = false
            this.allchecked = true
          } else if (show === 0) {
            this.indeterminate = false
            this.allchecked = false
          } else {
            this.indeterminate = true
            this.allchecked = false
          }
        }
      },
      selected: {
        handler() {
          this.checked = []
          this.dataList = []
          this.allchecked = false
          this.indeterminate = false
          this.isCheckedObj = {}
          this.refetchData()
        }
      }
    },
    directives: {
      Ripple,
    },
    setup() {

      const router = useRouter()

      const toast = useToast()
      const data = reactive({
        observer: undefined,
        dataList: [],
        warehouseId: parseInt(router.route.value.query.warehouseId || 0),
      })
      if (!store.hasModule('salesreturnbounditem')) store.registerModule('salesreturnbounditem', salesreturnbounditemStore)
      if (!store.hasModule('warehouselocation')) store.registerModule('warehouselocation', warehouselocationStore)
      if (!store.hasModule('salesreturnbound')) store.registerModule('salesreturnbound', salesreturnboundStore)

      onUnmounted(() => {
        if (store.hasModule('salesreturnbounditem')) store.unregisterModule('salesreturnbounditem')
        if (store.hasModule('warehouselocation')) store.unregisterModule('warehouselocation')
        if (store.hasModule('salesreturnbound')) store.unregisterModule('salesreturnbound')
      })

      const allSave = async function () {
        //this.showButton = false
        let itemIds = [];
        let returnQtyItemAll = [];
        let returnItemIds = [];
        let itemIdArr = []; //填写效期对应的 itemId
        let itemBatchNo = [];
        let itemLocationId = [];
        let itemQty = [];
        let itemStatus = []; //良品 不良品
        let itemYearAndMonth = [];
        data.dataList.forEach(item=>{
          if(!isEmpty(item.items)){
            item.items.forEach(obj=>{
              returnQtyItemAll.push(obj)
            })
          }
        })
        returnQtyItemAll.forEach(item=>{
          itemIds.push(item.id)
          itemIdArr.push(item.id)
          itemBatchNo.push(item.batchNo)
          itemLocationId.push(item.location_id)
          itemQty.push(item.qty)
          itemStatus.push(item.status)
          itemYearAndMonth.push(item.yearAndMonth)
        })

        itemIds = removeDuplicate(itemIds)
        let isExitQtyDif = false ; //数量是否存在差异
        data.dataList.forEach(item=>{
          console.log('item',item)
          let returnId = item.returnbounditem_id
          let returnQty = item.salesreturnqty
          returnItemIds.push(returnId)
          let qty = 0
          returnQtyItemAll.forEach(obj=>{
            if(obj.id === returnId){
              qty=addNumber(obj.qty,qty)
            }
          })
          console.log('qty',qty)
          if(returnQty!=qty){
            isExitQtyDif = true
          }
        })
        if(isExitQtyDif){ // 都设置效期了 但是不确定数量是否一致
          if (confirm("存在商品填写的退货数量与申请数量不一致，继续将完成入库。未填写数量的按0处理?")) {
            console.log("有差异")
            store.dispatch('salesreturnbounditem/returnboundAll', {
              itemIdArr: itemIdArr.join(","),
              itemBatchNo: itemBatchNo.join(","),
              itemLocationId: itemLocationId.join(","),
              itemQty: itemQty.join(","),
              itemStatus: itemStatus.join(","),
              itemYearAndMonth: itemYearAndMonth.join(","),
            }).then(res => {
              if (res.data.code === 0) {
                toast.success("入库成功")
                router.push({name: 'apps-salesreturnbound-warehouseList'})
              } else {
                toast.error(res.data.data)
              }
            })
          }
        }else{
          console.log("无差异")
          store.dispatch('salesreturnbounditem/returnboundAll', {
            itemIdArr: itemIdArr.join(","),
            itemBatchNo: itemBatchNo.join(","),
            itemLocationId: itemLocationId.join(","),
            itemQty: itemQty.join(","),
            itemStatus: itemStatus.join(","),
            itemYearAndMonth: itemYearAndMonth.join(","),
          }).then(res => {
            if (res.data.code === 0) {
              toast.success("入库成功")
              router.push({name: 'apps-salesreturnbound-warehouseList'})
            } else {
              toast.error(res.data.data)
            }
          })
        }
        //存在未选效期的明细
        // this.showButton = true
        // let ids = removeOneArrFromArr(returnItemIds,itemIds)
        // toast.error("退货明细："+ids.join(",")+"未选择效期!");
        // return;
      }

      const showModal = function (data) {
        this.condition = data
        this.$bvModal.show('receiveBackModal')
      }

      const fromChildren = function (val) {
        if (val.length>0) {
          let salesReturnItemId = val[0]['id']
          for(let i = 0 ;i<this.returnQtyItem.length;i++) {
            let returnItemId = this.returnQtyItem[i]['id']
            if (salesReturnItemId === returnItemId) {
              this.returnQtyItem.splice(i, 1)
              i-=1;
            }
          }
          val.forEach(item => {
            this.returnQtyItem.push(item)
          })
          // this.$emit("receiveItem",this.receiveQtyItem)
          this.$bvModal.hide('receiveBackModal')
        }
      }



      const rowClass = function (item, type) {
        if (!item || type !== 'row') {
          return
        }
        if (item.is_return===1) {
          return 'table-warning'
        }
        if (item.is_return===2) {
          return 'table-danger'
        }

      }

      const setValue = function () {
        let data = this.checked
        if(isEmpty(this.yearAndMonthHead)){
          toast.error("请选择效期!")
          return false;
        }
        if(isEmpty(this.locationInfo.value)){
          toast.error("请选择仓位!")
          return false;
        }
        if(data.length>0){
          data.forEach(obj=>{
            let item = {}
            let items = []
            item.id=obj.id;
            item.location_id=this.locationInfo.value;
            item.status=0;
            item.qty=obj.salesreturnqty
            item.location_fullname=this.locationInfo.location_fullname
            item.yearAndMonth= this.yearAndMonthHead
            item.batchNo = getBatchNoForTime(this.yearAndMonthHead,'yyyyMM')
            items.push(item)
            obj.items = items
            obj.is_return = 1
            obj.return_qty = obj.salesreturnqty
          })
          Notification({
            title: '成功',
            message: '赋值成功,请稍后点击入库完成!',
            type: 'success'
          });
        }else{
          toast.error("请勾选需要统一赋值的明细!")
        }
      }

      const useList = salesreturnbounditemUseList({
        returnbound_id: router.route.value.query.id || 0,
        warehouse_id: router.route.value.query.warehouseId || 0,
        readonly: router.route.value.query.readonly || 0
      })

      return {
        ...useList,
        // Filter
        toDate,
        ...toRefs(data),

        getCodeLabel,
        allSave,
        showModal,
        fromChildren,
        removeDuplicate,
        removeOneArrFromArr,
        rowClass,
        getBatchNoForTime,
        setValue,
        isEmpty,
        addNumber

      }
    },
    created() {
      const userData = getUserData()
      this.role_id = userData.role_id
      this.warehouseId = this.$route.query.warehouseId
    }
  }
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
