import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {getCodeLabel, isEmpty} from "@core/utils/filter";

export default function salesreturnbounditemUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: 'ID', sortable: true },
   /* { key: 'returnbound_id', label: '退货单ID', sortable: true },
    { key: 'order_id', label: '销售单ID', sortable: true },
    { key: 'orderitem_id', label: '销售单产品明细ID', sortable: true },
    { key: 'outbound_id', label: '出库单ID', sortable: true },
    { key: 'outbounditem_id', label: '出库单明细ID', sortable: true },
    { key: 'product_id', label: '商品ID', sortable: true },*/
    { key: 'select', label: '#'},
    { key: 'name', label: '名称', sortable: true },
    // { key: 'stock_id', label: 'ID', sortable: true },
    // { key: 'batch_no', label: '产品批次', sortable: true },
    // { key: 'production_date', label: '生产日期', sortable: true },
    // { key: 'expiry_date', label: '有效日期', sortable: true },
    { key: 'qty', label: '计划出库数量', sortable: true },
    { key: 'trueqty', label: '实际出库数量', sortable: true },
    { key: 'receiveqty', label: '对方实收数量', sortable: true },
    // { key: 'is_return', label: '是否已退回仓库', sortable: true },
    { key: 'salesreturnqty', label: '申请退货数量', sortable: true },
    { key: 'return_qty', label: '实际退货数量', sortable: true },
    // { key: 'salesreturnqty_good', label: '良品' },
    // { key: 'location_good', label: '仓位' },
    // { key: 'salesreturnqty_bad', label: '不良品' },
    // { key: 'location_bad', label: '仓位' },
    // { key: 'add_time', label: '添加时间', sortable: true },
    // { key: 'modify_time', label: '修改时间', sortable: true },
    //{ key: 'product_cost_tax', label: '含税单价(售价)', sortable: true },
    //{ key: 'cost_price', label: '成本价', sortable: true },
        { key: 'actions', label: '退货入库' },
  ]

 /* const start = ref(1)
  const limit = ref(1000)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)*/
  const searchKey = ref('')
  const goodLocation = ref({location_good_id:'',location_good_fullname:''})
  const badLocation = ref({location_bad_id:'',location_bad_fullname:''})
  const orderBy = ref('returnbounditem_id')
  const isSortDirDesc = ref(true)
  const warehouseIdLocationList = ref([])
  const salesReturnBound = ref({})

 /* const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })*/

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([ searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('salesreturnbounditem/search', {
        search_key: searchKey.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        returnbound_id:config.returnbound_id,
        readonly:config.readonly,
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        salesReturnBound.value = data.ext.salesReturnBound
        store.dispatch('warehouselocation/list',{
          warehouse_id:config.warehouse_id,
           order_by:'location_area',
           order_desc:'desc',
        }).then(res=>{
          const locationList = res.data.data.ext.list
          warehouseIdLocationList.value = [];
          for (let i = 0; i < locationList.length; i++) {
            warehouseIdLocationList.value.push({"label":getCodeLabel('warehouse_location', locationList[i].location_fullname),"value":locationList[i].location_id,"location_fullname":locationList[i].location_fullname})
          }
          var good;
          var bad;
          if(isEmpty(goodLocation.value.location_good_fullname)){
             good = locationList.filter(item => item['location_area'] === '0')[0]
          }else{
            good = locationList.filter(item => item['location_id'] === goodLocation.value.location_good_id)[0]
          }
          if(isEmpty(badLocation.value.location_bad_fullname)){
             bad = locationList.filter(item => item['location_area'] === '8')[0]
          }else{
             bad = locationList.filter(item => item['location_id'] === badLocation.value.location_bad_id)[0]
          }

          goodLocation.value.location_good_id = good.location_id
          goodLocation.value.location_good_fullname =getCodeLabel('warehouse_location', good.location_fullname)
          goodLocation.value.location_fullname = good.location_fullname
          badLocation.value.location_bad_id = bad.location_id
          badLocation.value.location_bad_fullname =getCodeLabel('warehouse_location', bad.location_fullname)
          // list.forEach(item=>{
          //      item['isChange'] = Boolean(item.is_return)
          //      item['location_good_id'] = good.location_id
          //      item['location_good_fullname'] =getCodeLabel('warehouse_location', good.location_fullname)
          //      item['location_bad_id'] = bad.location_id
          //      item['location_bad_fullname'] =getCodeLabel('warehouse_location', bad.location_fullname)
          //   if (item['salesreturnqty_good']==0&&item.is_return==0){
          //     item['salesreturnqty_good'] = item['salesreturnqty']
          //   }
          // })
          callback(list)
        })
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '销售退货单明细列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
  /*  limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,*/
    searchKey,
    badLocation,
    goodLocation,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,
    warehouseIdLocationList,
    salesReturnBound,


    // Extra Filters
  }
}
